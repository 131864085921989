import React from "react"
import Layout from "../components/layout"

const C = () => (
  <Layout>
    <p>C</p>
  </Layout>
)

export default C
